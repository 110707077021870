import React from 'react';

import { chunkArray, fillChunkedArray, pipe } from '../../../utils';

const Grid = ({ gap, offset, noPadding, children, className, ...props }) => {
  offset = offset ? ' grid--offset' : '';
  noPadding = noPadding ? ' grid--no-padding' : '';
  gap = (gap && ` grid--extra-gap-${gap}`) || '';
  return (
    <section className={`grid ${className || ''}${gap}${offset}${noPadding}`} {...props}>
      {children}
    </section>
  );
};

export const GridRow = ({ direction, isWrapped, isGrow = true, fixedColSize = 0, defaultColSize = 3, children, className, ...props }) => {
  if (fixedColSize > 0 && fixedColSize <= 12) {
    defaultColSize = fixedColSize;
  }
  direction = (direction && (direction === 'column' ? ' grid--col' : ' grid--row')) || '';
  const wrappedClass = isWrapped ? `${direction}--wrapped` : '';
  const growClass = isGrow ? '' : `${direction}--no-grow`;
  const composition = pipe(
    chunkArray,
    fillChunkedArray,
    // pop last chunk to fill up
    ([chunks, size]) => {
      return [chunks.pop(), size];
    },
    ([chunk, size]) => {
      return [chunk.filter(el => el === ""), size];
    }
  );
  return (
    <div className={`grid-row ${className || ''}${direction}${wrappedClass}${growClass}`} {...props}>
      {
        (fixedColSize === 0 && children)
        ||
        <>
          {
            children.map((el, index) => (
              <GridCol
                key={`grid-col-render-${index}`}
                {...el.props}
                className={`${el.props.className || ''}col-size-${fixedColSize} col-${defaultColSize}`}>{el.props && el.props.children}</GridCol>
            ))
          }{
            composition(
              [children, fixedColSize]
            )[0].map((domEl, index) => (
              <GridCol
                key={`grid-col-fillup-${index}`}
                {...children[0].props}
                className={`${children[0].props.className || ''}col-size-${fixedColSize} col-${defaultColSize} grid-row-col--fillup`}>{' '}</GridCol>
            ))
          }
        </>
      }
    </div>
  );
};

export const GridCol = ({ children, colSize, colOffsetSize, className, align, stretched, isFlex = true, isWrapped = false, colSpacing = 0, ...props }) => {
  const offsetClass = isWrapped ? ` offset-col-${colSpacing}` : '';
  const flexClass = isFlex ? '' : ' grid-row-col--non-flex';
  const alignment = (
    align && (
      align === 'left' ? 'grid-row-col--left' :
        align === 'center' ? 'grid-row-col--center' :
          align === 'right' ? 'grid-row-col--right' : ''
    )
  ) || '';
  stretched = stretched ? ' grid-row-col--stretched' : '';
  const colSizeClass = (colSize && ` col-${colSize}`) || '';
  const colOffsetSizeClass = (colOffsetSize && ` offset-col-${colOffsetSize}`) || '';
  return (
    <div className={`grid-row-col ${className || ''}${alignment}${stretched}${flexClass}${offsetClass}${colSizeClass}${colOffsetSizeClass}`} {...props}>
      {children}
    </div>
  );
};

export default Grid;
