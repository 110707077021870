import React from 'react';
import { navigate } from 'gatsby';

import { go, scrollTo } from '../../../utils';

const Button = ({ to, text, icon, background, isBlock = false, target, className, ...props }) => {
  const backgroundClass =
    background === 'transparent' ? ' button--cta-transparent' :
      background === 'alert' ? ' button--cta-alert' : ''
    ;
  const iconClass = icon ? ' button--has-icon' : '';
  const blockClass = isBlock ? ' button--fullwidth' : '';
  const eventHandler = (event, url, target) => {
    const [start] = url.slice(0, 1);

    if (start === '#') {
      return scrollTo(event, url)
    }

    return target === "_blank" ? go(url) : navigate(url);
  };
  return (
    <button text={text} className={`button button--cta ${className || ''}${iconClass}${blockClass}${backgroundClass}`} onClick={event => { eventHandler(event, to, target); }} icon={icon} {...props} >{text}</button>
  );
};

export default Button;
