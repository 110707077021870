import React from 'react';

import { scrollHandler, detachEventListeners } from '../../../utils';

import { mainNavigationScrollHandler } from '../organisms/mainNavigation';

export default class extends React.Component {

  componentDidMount() {
    scrollHandler(window, mainNavigationScrollHandler);
  }

  componentWillUnmount(){
    detachEventListeners([
      { target: window, event: 'scroll', callback: mainNavigationScrollHandler}
    ]);
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
};