import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { createMarkup } from '../../../utils';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({ src, alt, caption, title, isInvisible, className, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(quality: 100) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n =>
        n.node.relativePath.includes(src)
      );
      if (!image) {
        return null;
      }

      const { sizes, fluid } = image.node.childImageSharp;
      const invisibleClass = isInvisible ? 'image--invisible' : '';

      if (caption) {
        return (
          <figure className={`${invisibleClass}`} title={title} >
            <Img
              alt={alt}
              sizes={sizes}
              fluid={fluid}
              fadeIn={false}
              className={`${className || ''}`}
              isInvisible={isInvisible}
              title={title}
              {...props} />
            <div className="caption" dangerouslySetInnerHTML={createMarkup(caption)} />
          </figure>
        );
      }
      return (
        <figure className={`${invisibleClass}`} title={title}>
          <Img
            alt={alt}
            sizes={sizes}
            fluid={fluid}
            fadeIn={false}
            isInvisible={isInvisible}
            title={title}
            className={`${className || ''}`} />
        </figure>
      );
    }}
  />
);

export default Image;
