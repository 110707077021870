import React from 'react';
import { Link as GLink } from 'gatsby';
import { scrollTo } from '../../../utils';

const Link = ({ target, icon, label, to, type, fullWidthCtas, children, className, ...props }) => {
  const iconClass = icon ? ' link--has-icon' : '';
  const buttonClass = type && type === 'button' ? ' link--cta link--block' : '';
  const isFullWidth = fullWidthCtas ? ' link--fullwidth' : '';
  const isExternalLink = to && to.indexOf("://") > 0 ? true : false;
  const isBlankTarget = target === '_blank' ? true : false;
  const isAnchorLink = to && to.indexOf("#") === 0 ? true : false;
  const clickHandler = isAnchorLink ? {
    onClick: scrollTo
  } : {};
  return (
    isBlankTarget || isAnchorLink || isExternalLink ?
      <a
        className={`link ${className || ''}${iconClass}${buttonClass}${isFullWidth}`}
        title={label}
        alt={label}
        target={target}
        label={label}
        type="button"
        text={label}
        icon={icon}
        href={to}
        {...clickHandler}
        {...props}>{children}</a>
      :
      <GLink
        className={`link ${className || ''}${iconClass}${buttonClass}${isFullWidth}`}
        title={label}
        alt={label}
        label={label}
        icon={icon}
        to={to}
        text={label}
        {...props}>{children}</GLink>
  );
};

export default Link;
